<template>
    <div>
        <div class="form-row form-row_grid">
            <div class="form-col">
                <label>content alignment</label>
                <div class="radio-group mt-4">
                    <div class="radio">
                        <input type="radio" name="alignment" id="alignmentLeft" value="left" v-model="block.alignment">
                        <label for="alignmentLeft">
                            <icon name="alignLeft" class="w-5 h-5 text-black"></icon>
                        </label>
                    </div>
                    <div class="radio">
                        <input type="radio" name="alignment" id="alignmentCenter" value="center" v-model="block.alignment">
                        <label for="alignmentCenter">
                            <icon name="alignCenter" class="w-5 h-5 text-black"></icon>
                        </label>
                    </div>
                    <div class="radio">
                        <input type="radio" name="alignment" id="alignmentRight" value="right" v-model="block.alignment">
                        <label for="alignmentRight">
                            <icon name="alignRight" class="w-5 h-5 text-black"></icon>
                        </label>
                    </div>
                </div>
                <!-- <label>content alignment</label>
                <toggle-buttons :options="alignments" v-model="block.alignment"></toggle-buttons> -->

                <label for="headline" class="mt-8">headline</label>
                <text-input id="headline" v-model="block.headline" autocomplete="off" />

                <span v-if="hasError('block.headline')" class="form-error">{{ showError('block.headline') }}</span>

                <label for="subHeadline" class="mt-8">sub-headline</label>
                <text-input id="subHeadline" v-model="block.subHeadline" autocomplete="off" />

                <label class="mt-8">image</label>
                <file-manager
                    :main-directory="'cms'"
                    :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                    :libraryFilters="['marketing', 'cms']"
                    :max-nb-of-files="1"
                    :max-upload-size="20"
                    :cropper-options="{aspectRatio: 1, cropBoxResizable: true, zoomOnWheel: false}"
                    :noCropper="true"
                    @uploaded-to-s3="createWebsiteImage"
                    v-model="block.image"
                ></file-manager>
                <span v-if="hasError('block.image')" class="form-error">{{ showError('block.image') }}</span>
            </div>
            <div class="form-col">
                <label>content</label>
                <vue-editor v-model="block.content" id="content" :editorToolbar="customToolbar"></vue-editor>
                <span v-if="hasError('block.content')" class="form-error">{{ showError('block.content') }}</span>
            </div>
        </div>
        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Widget from "@/mixins/Widget";
    import Icon from "@/components/ui/Icon";
    import { VueEditor } from "vue2-editor";
    import { toolbarConfig } from "@/utils/RichTextEditor";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import FileManager from "@/components/ui/filemanager/FileManager";
    import TextInput from '@/components/ui/TextInput';

    export default {
        mixins: [ ModalNavigation, Widget ],
        components: {ModalFooter, Icon, FileManager, VueEditor, TextInput },
        emits: ['save-page-widget'],
        data: () => {
            return {
                alignments: [
                    { value: 'left', label: 'left'},
                    { value: 'center', label: 'center'},
                    { value: 'right', label: 'right'},
                ],
                block: {
                    alignment: 'center',
                    headline: null,
                    subHeadline: null,
                    content: null,
                    image: []
                },
                customToolbar: toolbarConfig,
                isProcessing: false,
            }
        },
        computed: {
            ...mapGetters({
                getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
                getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
                getFileIdByThumbnailUrl: 'files/getFileIdByThumbnailUrl'
            })
        },
        methods: {
            save() {
                if(this.validator.passes()) {
                    this.isProcessing = true;
                    this.block.image = this.getThumbnailUrlByFileId(this.block.image[0]);
                    this.$emit('save-page-widget', Object.assign({}, this.block));
                }
            },
        },
        validations: {
            'block.headline' : 'required',
            'block.content' : 'required',
            'block.image': 'required'
        },
        created() {
            this.initValidator();

            if (this.values.length) {
                Object.assign(this.block, {
                    alignment: this.getValue('alignment'),
                    headline: this.getValue('headline'),
                    subHeadline: this.getValue('subHeadline'),
                    content: this.getValue('content'),
                    image: (this.getValue('image') ) ? [ this.getFileIdByThumbnailUrl(this.getValue('image')) ] : []
                });
            }
        }
    }
</script>
<style scoped>
    .radio-group{
        display:flex;
        align-items: center;
    }
    .radio-group .radio + .radio{
        margin-left: 1.5rem;
    }
    .radio label{
        display:flex;
        align-items: center;
    }
    .radio input{
        margin-right: .5rem;
    }
</style>
